
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import useFetchSearch from "./hooks/useFetchSearch";
import { listenFullScreen, STATUS } from "@/utils";
import { searchParams as iterationId } from "@/views/iteration/useMixin";
import useGetTableData from "@/composables/useGetTableData";
import { useRouter } from "vue-router";
import IterationTip from "@/components/iteration-tip/index.vue";
import useWatchChangeProduct from "@/composables/useWatchChangeProduct";
import { toggleFullScreen } from "@/utils/index";
import { Pagation } from "@/composables/usePagation";

export default defineComponent({
  name: "allOverview",
  components: { IterationTip },
  setup() {
    const isFullScreen = ref(false); // 是否全屏
    const pageTableRef = ref();
    const searchParams = reactive({
      name: "",
      status: [],
      product_id: ""
    });
    const router = useRouter();
    const curStatus = computed(function () {
      return function (row: any) {
        const { complete_percent, time_percent } = row;
        return complete_percent - time_percent;
      };
    });

    const tableRowClassName = ({ row }: { row: Record<string, any>; rowIndex: number }) => {
      if (row.status === 0) {
        return "no-start";
      } else if (row.status === 6 || row.status === 7) {
        return "has-end";
      }
      return "to-do";
    };

    const getStatus = (index: number) => {
      const status = STATUS.find((v) => v.value === index);
      return status?.label;
    };
    const { getData, tableData, stopAutoGetData, currentPage } = useGetTableData(useFetchSearch, searchParams);
    // getData();
    // let timer: ReturnType<typeof setTimeout>;
    const handleConditionSearch = async (pagationParams?: Pagation, isHiddenSelect?: boolean) => {
      if (isHiddenSelect === true) {
        return;
      }

      currentPage.value = (pagationParams && pagationParams.pageIndex) || 1;
      // 获取当前page_size，如果有缓存，不设置，没有缓存默认第一页
      await getData(pageTableRef.value.getCurrentPage(), true, searchParams);
      stopAutoGetData.value = false;

      const contentHeight = document.getElementsByClassName("content")[0] as HTMLDivElement;
      const headerHeight = document.getElementsByClassName("search-header")[0] as HTMLDivElement;
      pageTableRef.value.height = contentHeight.offsetHeight - headerHeight.offsetHeight - 130;
    };
    const progressNode = (row: Record<string, any>) => {
      let createTime = row.plan ? row.plan.create_time : "";
      return [
        {
          time: createTime,
          realTime: createTime
        },
        {
          time: row.dev_time,
          realTime: row.real_dev_time
        },
        {
          time: row.union_time,
          realTime: row.real_union_time
        },
        {
          time: row.test_time,
          realTime: row.real_test_time
        },
        {
          time: row.release_time,
          realTime: row.real_release_time
        }
      ];
      // return [createTime, row.dev_time, row.union_time, row.test_time, row.release_time];
    };
    const handleClear = () => {
      searchParams.name = "";
      handleConditionSearch();
    };
    useWatchChangeProduct(handleConditionSearch, (newValue) => {
      searchParams.product_id = newValue as string;
      handleConditionSearch();
    });
    const handleShowDetail = (id: number) => {
      iterationId.demand = id;
      router.push({
        name: "homepage"
      });
    };
    const handleStatus = (val: number[]) => {
      if (!val.length) {
        searchParams.status = [];
        handleConditionSearch();
      }
    };
    const getTextColor = (status: number) => {
      const list = STATUS.find((v: { value: number; label: string }) => v.value === status);
      return {
        color: list?.color
      };
    };
    const handleFullClass = () => {
      const main = document.querySelector(".main");
      if (main) {
        if (main.classList.contains("isFullScreen")) {
          main.classList.remove("isFullScreen");
        } else {
          main.classList.add("isFullScreen");
        }
      }
    };
    // 操作列表全屏
    const handleFullscreen = () => {
      listenFullScreen((isClose: boolean) => {
        console.log(isClose, "isClose");

        handleFullClass();
      });
      isFullScreen.value = true;
      handleFullClass();
      toggleFullScreen();
    };

    return {
      curStatus,
      isFullScreen,
      getTextColor,
      getStatus,
      getData,
      handleShowDetail,
      progressNode,
      handleConditionSearch,
      STATUS,
      ...toRefs(tableData),
      searchParams,
      pageTableRef,
      handleClear,
      handleStatus,
      handleFullscreen,
      tableRowClassName,
      currentPage
    };
  }
});
